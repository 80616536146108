import { Login } from "../pages/Login/Login";
import { Register } from "../pages/Register/Register";
import { Verify } from "../pages/Verify/Verify";
import { Services } from "../pages/Services/Services";
import { Galerija } from "../pages/Galerija/Galerija";
import { OpstaMedicinskaNjega } from "../pages/Opsta_i_medicinska_njega/OpstaMedicinskaNjega";
import { Kontakt } from "../pages/Kontakt/Kontakt";
import { ONama } from "../pages/O_Nama/ONama";
import { Pocetna } from "../pages/Pocetna/Pocetna";
import { Rehabilitacija } from "../pages/RehabilitacijaOsobe/Rehabilitacija";
import { SucessStories } from "../pages/Sucess_Stories/SucessStories";
import { WhyUsPage } from "../pages/WhyUs/WhyUsPage";
import { Konsultacije } from "../pages/Konsultacije/Konsultacije";
import { Posjete } from "../pages/Posjete/Posjete";
import { Smjestaj } from "../pages/Smjestaj/Smjestaj";
import { Dom } from "../pages/Dnevni boravak/Dom";
//import { MozdaniUdar } from "../pages/Mozdani udar/MozdaniUdar";
import { RobotskaRehab } from "../pages/Robotska rehabilitacija/RobotskaRehab";
import { Portal } from "../pages/Portal/Portal";
import { VrRehab } from "../pages/Vr rehabilitacija/VrRehab";
import { BartelSkala } from "../pages/Bartel skala/BartelSkala";

const router = [
  {
    path: "/",
    element: <Pocetna />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/verifikacija",
    element: <Verify />,
  },
  {
    path: "/servisi",
    element: <Services />,
  },
  {
    path: "/bartel-skala",
    element: <BartelSkala />,
  },
  {
    path: "/o-nama",
    element: <ONama />,
  },
  {
    path: "/whyus",
    element: <WhyUsPage />,
  },
  {
    path: "/smjestaj",
    element: <Smjestaj />,
  },
  {
    path: "/opstamedicinskanjega",
    element: <OpstaMedicinskaNjega />,
  },
  {
    path: "/rehabilitacija",
    element: <Rehabilitacija />,
  },
  /*
  {
    path: "/mozdaniUdar",
    element: <MozdaniUdar />,
  },
  */
  {
    path: "/robotskaRehab",
    element: <RobotskaRehab />,
  },
  {
    path: "/vrRehab",
    element: <VrRehab />,
  },
  {
    path: "/dom",
    element: <Dom />,
  },
  {
    path: "/galerija",
    element: <Galerija />,
  },
  {
    path: "/sucess-stories",
    element: <SucessStories />,
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
  },
  {
    path: "/konsultacije",
    element: <Konsultacije />,
  },
  {
    path: "/posjete",
    element: <Posjete />,
  },
  {
    path: "/portal",
    element: <Portal />,
  },
];

export default router;
