import queries from "./queries";

const leadService = queries.injectEndpoints({
  endpoints: (builder) => ({
    createLeadServices: builder.mutation({
      query: (services) => {
        return {
          method: "PUT",
          url: "/lead",
          body: services,
        };
      },
    }),
    createBartelScale: builder.mutation({
      query: (services) => {
        return {
          method: "PATCH",
          url: "/lead/barthel-index",
          body: services,
        };
      },
    }),
    getLeadServices: builder.query({
      query: () => {
        return {
          method: "GET",
          url: "/lead",
        };
      },
    }),
  }),
});

export default leadService;
