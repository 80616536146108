import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useCreateBartelScaleMutation } from "../../services";
import Logo from "../../assets/Logo/logo.png";

export const BartelSkala = () => {
  const [createBartelScale, { isLoading, isSuccess, error }] =
    useCreateBartelScaleMutation();
  const [rezultat, setRezultat] = useState(null);
  const [kategorija, setKategorija] = useState("");

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const calculateScore = (data) => {
    const activityScores = {
      hranjenje: { 1: 10, 2: 5, 3: 0 },
      kupanje: { 1: 5, 2: 0 },
      licna_njega: { 1: 5, 2: 0 },
      oblacenje: { 1: 10, 2: 5, 3: 0 },
      kontrola_rada_crijeva: { 1: 10, 2: 5, 3: 0 },
      kontrola_mokrenja: { 1: 10, 2: 5, 3: 0 },
      koristenje_wc: { 1: 10, 2: 5, 3: 0 },
      transferi_sa_kreveta_na_stolicu_i_nazad: { 1: 15, 2: 10, 3: 5, 4: 0 },
      mobilnost_na_ravnim_povrsinama: { 1: 15, 2: 10, 3: 5, 4: 0 },
      koristenje_stepenica: { 1: 10, 2: 5, 3: 0 },
    };

    let totalScore = 0;
    Object.keys(data).forEach((key) => {
      totalScore += activityScores[key]?.[data[key]] || 0;
    });

    return totalScore;
  };

  const classifyPatient = (score) => {
    if (score > 80 || score === 100) return "Samostalni pacijenti";
    if (score > 60 && score < 81) return "Djelimično ovisni pacijenti";
    if (score > 40 && score < 61) return "Umjereno ovisni pacijenti";
    if (score > 20 && score < 41) return "Teško ovisni pacijenti";
    if (score < 21) return "Potpuno ovisni pacijenti";
    return "Nepoznato";
  };

  const mapValueToActivity = (value) => {
    const mapping = {
      1: "nezavisno",
      2: "potrebna_pomoc",
      3: "nesposoban",
      4: "povremena_nezgoda",
      5: "inkontinentan",
    };
    return mapping[value] || "nezavisno";
  };

  const onSubmit = (data) => {
    const totalScore = calculateScore(data);
    const patientCategory = classifyPatient(totalScore);

    setRezultat(totalScore);
    setKategorija(patientCategory);

    const transformedData = {
      activities: Object.keys(data).reduce(
        (acc, key) => ({
          ...acc,
          [key]: mapValueToActivity(data[key]),
        }),
        {}
      ),
      index: totalScore,
      category: patientCategory,
    };

    createBartelScale(transformedData);
  };

  const activityOptions = {
    hranjenje: [
      { value: 1, label: "Nezavisno" },
      { value: 2, label: "Potrebna pomoć" },
      { value: 3, label: "Nesposoban" },
    ],
    kupanje: [
      { value: 1, label: "Nezavisno" },
      { value: 2, label: "Nesposoban" },
    ],
    licna_njega: [
      { value: 1, label: "Nezavisno" },
      { value: 2, label: "Nesposoban" },
    ],
    oblacenje: [
      { value: 1, label: "Nezavisno" },
      { value: 2, label: "Potrebna pomoć" },
      { value: 3, label: "Nesposoban" },
    ],
    kontrola_rada_crijeva: [
      { value: 1, label: "Kontrolisan" },
      { value: 2, label: "Povremena nezgoda" },
      { value: 3, label: "Inkontinentan" },
    ],
    kontrola_mokrenja: [
      { value: 1, label: "Kontrolisan" },
      { value: 2, label: "Povremena nezgoda" },
      { value: 3, label: "Inkontinentan" },
    ],
    koristenje_wc: [
      { value: 1, label: "Nezavisno" },
      { value: 2, label: "Potrebna pomoć" },
      { value: 3, label: "Nesposoban" },
    ],
    transferi_sa_kreveta_na_stolicu_i_nazad: [
      { value: 1, label: "Nezavisno" },
      { value: 2, label: "Minimalna pomoć" },
      { value: 3, label: "Veća pomoć" },
      { value: 4, label: "Nesposoban" },
    ],
    mobilnost_na_ravnim_povrsinama: [
      { value: 1, label: "Samostalno" },
      { value: 2, label: "Hoda uz pomoć jedne osobe" },
      { value: 3, label: "Nezavisan je od invalidskih kolica" },
      { value: 4, label: "Nepokretan" },
    ],
    koristenje_stepenica: [
      { value: 1, label: "Nezavisno" },
      { value: 2, label: "Potrebna pomoć" },
      { value: 3, label: "Nesposoban" },
    ],
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-svijetloPlava1 to-blue-500">
      <div className="w-full max-w-md p-6 md:p-8 space-y-3 md:space-y-4 bg-white bg-opacity-50 shadow-lg rounded-lg mt-[110px] mb-[15px]">
        <img
          src={Logo}
          alt="Logo Familia"
          className="w-[250px] md:w-[250px] lg:w-[250px] mx-auto"
        />
        <h2 className="text-2xl font-poppins md:text-3xl font-normal text-center text-gray-700">
          Bartelov Indeks
        </h2>
        <p className="text-center font-light text-gray-700">
          Barthel indeks je medicinski alat koji procjenjuje funkcionalnu
          neovisnost u osnovnim životnim aktivnostima.
        </p>
        <hr className="my-4 border-t-4 border-TamnoPlava rounded-full w-[100%] lg:w-[90%] mx-auto" />
        <form className="mt-6 md:mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {Object.entries(activityOptions).map(([activityKey, options]) => (
            <div key={activityKey}>
              <p className="text-center md:text-left">
                {activityKey
                  .replace(/_/g, " ")
                  .replace(/^\w/, (c) => c.toUpperCase())}
              </p>
              <Controller
                name={activityKey}
                control={control}
                defaultValue={options[0].value}
                rules={{ required: true }}
                render={({ field: { ref, value, onChange } }) => (
                  <select
                    ref={ref}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                    value={value}
                    onChange={onChange}
                  >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
              />
            </div>
          ))}
          <p
            className={`text-center font-semibold ${
              isSuccess ? "text-green-500" : "text-red-500"
            }`}
          >
            {isSuccess ? "Hvala na popunjenoj formi!" : error?.data?.message}
          </p>
          <button
            id="submit-button"
            disabled={!isValid || isLoading || isSuccess}
            className="w-full h-[50px] border-[3px] border-Narandzasta rounded-2xl transition-all duration-300 ease-in-out cursor-pointer bg-white text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
            type="submit"
          >
            {isLoading ? "Šaljem podatke..." : "Pošalji"}
          </button>
        </form>
        {rezultat !== null && (
          <div className="text-center mt-4">
            <p className="text-lg font-semibold">Ukupni Rezultat: {rezultat}</p>
            <p className="text-base text-gray-700">Kategorija: {kategorija}</p>
          </div>
        )}
      </div>
    </div>
  );
};
